@import '@lumapps/lumx/scss/variables.scss';

$searchbox-height-header: map-get($lumx-sizes, lumx-base-const('size', 'M'));
$searchbox-min-width-header: 250px;
$searchbox-max-width-header: 500px;
$searchbox-height-search-page: 48px;

.searchbox {
    $self: &;
    border-radius: $lumx-border-radius 0 0 $lumx-border-radius;

    .lumx-text-field__wrapper,
    .lumx-text-field__wrapper::before {
        border-radius: $lumx-border-radius 0 0 $lumx-border-radius !important;
    }

    .theme-material & {
        --lumx-button-emphasis-low-state-default-theme-light-color: var(--lumx-color-dark-L1);

        &--theme-light {
            background-color: $lumx-color-dark-L6;
        }
    }

    &--theme-dark {
        .theme-material & {
            background-color: $lumx-color-light-N;
            --lumx-text-field-state-default-theme-dark-input-content-color: var(
                --lumx-text-field-state-default-theme-light-input-content-color
            );
            --lumx-text-field-state-hover-theme-dark-input-content-color: var(
                --lumx-text-field-state-hover-theme-light-input-content-color
            );
            --lumx-text-field-state-focus-theme-dark-input-content-color: var(
                --lumx-text-field-state-focus-theme-light-input-content-color
            );
            --lumx-button-emphasis-low-state-default-theme-dark-color: var(
                --lumx-button-emphasis-low-state-default-theme-light-color
            );
            --lumx-button-emphasis-low-state-hover-theme-dark-color: var(
                --lumx-button-emphasis-low-state-hover-theme-light-color
            );
            --lumx-button-emphasis-low-state-focus-theme-dark-color: var(
                --lumx-button-emphasis-low-state-focus-theme-light-color
            );

            .lumx-text-field__input-native::placeholder {
                color: var(--lumx-text-field-state-default-theme-light-input-placeholder-color);
            }
        }
    }

    &__wrapper {
        justify-content: flex-end;
    }

    &__suggestions {
        .lumx-flex-box--v-align-space-between {
            justify-content: space-between;
        }

        &-with-max-width {
            max-width: $searchbox-max-width-header;
        }
    }

    &__submit-btn {
        border-radius: 0 $lumx-border-radius $lumx-border-radius 0 !important;

        &--is-mobile {
            height: $searchbox-height-search-page !important;
            width: $searchbox-height-search-page !important;
        }
    }

    &--is-mobile {
        flex: 1;

        .lumx-text-field__input-clear {
            height: $searchbox-height-header !important;
            width: $searchbox-height-header !important;
        }
    }

    &--has-contextual-searchbox {
        flex: 1;
    }

    .header & {
        flex: 1;
        max-width: $searchbox-max-width-header;
        min-width: $searchbox-min-width-header;
        height: $searchbox-height-header;

        .theme-lumapps & {
            --lumx-text-field-state-default-input-border-top-width: 0;
            --lumx-text-field-state-default-input-border-bottom-width: 0;
            --lumx-text-field-state-default-input-border-left-width: 0;
            --lumx-text-field-state-default-input-border-right-width: 0;
            --lumx-text-field-state-hover-input-border-top-width: 0;
            --lumx-text-field-state-hover-input-border-bottom-width: 0;
            --lumx-text-field-state-hover-input-border-left-width: 0;
            --lumx-text-field-state-hover-input-border-right-width: 0;
        }
    }

    .lumx-text-field {
        padding: 0;
        height: 100%;

        .theme-lumapps & {
            padding: 0;
        }

        .theme-material & {
            &--is-focus {
                .lumx-text-field__wrapper {
                    &::before {
                        border-width: 1px !important;
                    }
                }
            }
        }

        &__wrapper {
            padding: 0 $lumx-spacing-unit 0 $lumx-spacing-unit-big !important;
            height: 100%;

            .theme-material & {
                &::before {
                    border-width: 0 !important;
                }

                &:hover::before {
                    border-width: 1px !important;
                }
            }

            .theme-lumapps & {
                padding: 0 var(--lumx-text-field-input-padding-horizontal) !important;

                &::before {
                    display: block;
                }
            }
        }

        &__input-wrapper {
            height: 100%;

            input {
                height: 100%;
            }
        }

        &__input-native input {
            padding: 1px 0;

            .theme-lumapps & {
                padding: 0;
            }

            .header & {
                height: $searchbox-height-header;
            }

            .search-page & {
                height: $searchbox-height-search-page;
            }
        }

        .theme-lumapps & {
            padding-top: 0;
            padding-bottom: 0;
        }
    }
}
