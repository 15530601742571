@import '@lumapps/wrex-core/scss/mixins.scss';

.wrex-content-table {
    @include wrexContentBlock();
    width: 100%;
    table-layout: fixed;

    &--in-cell-selection-mode *::selection {
        background-color: transparent;
    }
}
