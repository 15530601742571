@import '@lumapps/lumx/scss/variables.scss';

.gdpr-banner {
    position: fixed;
    top: null;
    right: null;
    bottom: 0;
    left: 0;
    width: 100%;
    min-height: 48px;
    background-color: lumx-color-variant('black', 'N');
    color: lumx-color-variant('white', 'L1');
    // The banner needs an high z-index to be above tutorial and anything else in the future. The T&C Dialog has a 1000 z-index and it should always appear above the GdprBanner.
    z-index: 999;
    padding: $lumx-spacing-unit * 3 0;

    &__wrapper {
        @include wrapper();
        display: flex;
        align-items: center;
    }

    &__content {
        flex: 1;
        margin-right: $lumx-spacing-unit * 3;
    }

    &__button {
        flex: 1;
        margin: $lumx-spacing-unit 0;
    }

    &__title {
        font-size: map-get($lumx-sizes, 'xs');
        line-height: $lumx-spacing-unit * 4;
        font-weight: 500;
    }

    &__text {
        font-size: map-get($lumx-sizes, 'xxs');
        flex: 1;
    }

    &__btn {
        box-sizing: content-box;
        padding: $lumx-spacing-unit;
        cursor: pointer;
    }
}
