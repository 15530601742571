@use "sass:map";
@import "../../../scss/variables";

.lumx-menu {
  width: 100%;

  ul, li {
    list-style: none;
  }

  // Menu item
  .lumx-menu-item {
    &__wrapper {
      appearance: none;
      border: 0;
      text-align: left;
      width: 100%;

      // Add an indicator on keyboard navigation and not hovering with mouse
      // TODO: use list item style (once migrated in the DS)
      &[data-focus-visible-added]:not(:hover) {
        outline: 2px solid var(--lumx-color-dark-N);
        outline-offset: -2px;
      }
    }
  }

  // Menu section
  .lumx-menu-section {
    // Automatic divider before & after (if needed)
    @include between('.lumx-menu-section') using($position) {
      @if $position == 'before' {
        margin-top: $lumx-spacing-unit-regular;
        border-top: 1px solid lumx-color-variant("dark", $lumx-divider-color-variant);
        padding-top: $lumx-spacing-unit-regular;
      } @else {
        // Skip when section is empty
        &:not(:has(ul:empty)) {
          margin-bottom: $lumx-spacing-unit-regular;
          border-bottom: 1px solid lumx-color-variant("dark", $lumx-divider-color-variant);
          padding-bottom: $lumx-spacing-unit-regular;
        }
      }
    }
  }
}

.lumx-menu-popover {
  overflow-y: auto;

  &--default-width {
    // Default popover width. Can be overridden via props.
    max-width: map-get($lumx-sizes, 'xxl');
  }
}

.lumx-menu-disclosure {
  padding-left: $lumx-spacing-unit-huge;
}

// Menu skeleton
.lumx-menu-skeleton {
  flex-grow: 1;
}

// Menu item skeleton
.lumx-menu-item-skeleton {
  @include lumx-list-item('tiny');
  @include lumx-list-item-padding('big');

  &__before {
    margin-right: $lumx-spacing-unit-regular;
  }

  &__content {
    flex: 1 1 auto;
  }
}

.lumx-menu-item {
  &__wrapper {
    // Allow interaction with aria-disabled menu items
    &[aria-disabled='true']:not([disabled]) {
      pointer-events: initial !important;
    }
  }
}
