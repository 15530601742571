@import '@lumapps/lumx/scss/variables';

.filters-dropdown {
    &__header {
        justify-content: space-between;
        margin-bottom: $lumx-spacing-unit;
    }

    &__filters {
        padding: 0 $lumx-spacing-unit-huge;
        min-width: 300px;
        /** allow scrolling inside the filters dropdown */
        overflow: auto;
        min-height: unset !important;
        /** roughly around 4 filters and a bit of the next one */
        max-height: 385px;
    }

    &__footer {
        justify-content: flex-end;
    }
}
