@mixin wrapper($width: 'wide') {
    @include mq($until: small) {
        margin-right: $lumx-spacing-unit-big;
        margin-left: $lumx-spacing-unit-big;
        max-width: calc(100vw - #{$lumx-spacing-unit-big * 2});
    }

    @include mq($until: desktop) {
        margin-right: $lumx-spacing-unit-huge;
        margin-left: $lumx-spacing-unit-huge;
        max-width: calc(100vw - #{$lumx-spacing-unit-huge * 2});
    }

    @include mq($from: desktop) {
        margin-right: auto;
        margin-left: auto;
    }

    @include mq($from: desktop, $until: wide) {
        width: 960px;
    }

    @include mq($from: wide) {
        width: 1128px;
    }

    @if ($width == 'compact') {
        @include mq($from: tablet, $until: desktop) {
            width: 552px;
            margin-left: auto;
            margin-right: auto;
        }
    
        @include mq($from: desktop) {
            width: 936px;
        }
    }
}