@import '@lumapps/lumx/scss/variables';
@import "@lumapps/wrex-core/scss/mixins.scss";

.wrex-content-title {
    @include wrexContentBlock();

    .theme-material & {
        @include lumx-typography('title');
    }

    .theme-lumapps & {
        @include lumx-typography('title3', 'custom');
    }

    .theme-lumapps .block-comments &,
    .theme-lumapps .block-post &,
    .theme-lumapps .posts-post-dialog & {
        @include lumx-typography('title6', 'custom');
    }
}
