@import '@lumapps/lumx/scss/variables';
@import '@lumapps/wrex-core/scss/mixins.scss';

.wrex-content-quote-block {
    @include wrexContentBlock();
    position: relative;
    padding-left: $lumx-spacing-unit * 2.5;

    &::before {
        content: '';
        display: block;

        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;

        width: $lumx-spacing-unit-tiny;

        background-color: lumx-color-variant('dark', 'L4');

        border-radius: 2px;

        .wrex-editor--theme-dark & {
            background-color: lumx-color-variant('light', 'L2');
        }
    }
}

.wrex-content-quote-block--theme-dark {
    &::before {
        background-color: lumx-color-variant('light', 'L2');
    }
}
