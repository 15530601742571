@import '@lumx/core/scss/_design-tokens.scss';
@import '@lumx/core/scss/_core.scss';
@import '@lumx/core/scss/_components';
@import '@lumapps/responsive/index.scss';
@import './animations.scss';
@import './wrapper.scss';
@import './mixins.scss';

$header-z-index: 997;
$header-top-bar-height: 60px;
$header-navigation-height: 48px;
$header-height: $header-top-bar-height + $header-navigation-height;
$admin-nav-width: 280px;
