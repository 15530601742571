@import '../../../../scss/variables.scss';

.dropdown-section {
    width: map-get($lumx-sizes, 'xxl');
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: 0;
    height: 100%;

    &:not(:first-child) {
        padding-top: $lumx-spacing-unit-regular;
    }

    &:not(:last-child) {
        padding-bottom: $lumx-spacing-unit-regular;
        border-bottom: solid 1px lumx-color-variant('dark', 'L6');
    }

    &__wrapper {
        flex: 1;
        display: flex;
        flex-direction: column;
        min-height: 0;
        overflow: auto;

        /** IE 11 specific CSS */
        @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {  
            flex: none;
            display: -ms-flexbox;
            overflow: hidden;
         }
    }

    &__subheader {
        @include lumx-list-subheader;
        padding-left: $lumx-spacing-unit-big;
        padding-right: $lumx-spacing-unit-big;
    }
}
