@import '@lumapps/lumx/scss/variables';

.page {
    @include fade-in-animate;
    position: relative;
    flex: 1 0 auto;
    margin-bottom: $lumx-spacing-unit-huge;

    &--is-loading {
        min-height: 100vh;
    }

    &--wide {
        @include wrapper();
    }

    &--compact {
        @include wrapper('compact');
    }

    &--has-background {
        background-color: lumx-color-variant('light', 'N');
    }
}
