@import '../../../../scss/variables.scss';

$item-wrapper: '.lumx-list-item__wrapper';
$item-link: '.lumx-list-item__link';
$item-content: '.lumx-list-item__content';

.dropdown-item {
    list-style: none;

    #{$item-link} {
        padding: $lumx-spacing-unit-tiny $lumx-spacing-unit-big;
        min-width: map-get($lumx-sizes, 'xxl');

        #{$item-content} {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }

    &__item-text {
        min-width: 0;
        @include lumx-typography('subtitle1');
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-weight: normal;
    }
}
