@use 'sass:math';

// Mixin for print
@mixin print() {
    @media print {
        .body--print & {
            @content;
        }
    }
}

///  Maintain aspect ratio of an element
///  If content takes bigger in size that the defined ratio (texts for example),
///  the ratio will be adapted accordingly.
///
///  @access public
///  @group mixins
///
///  @param $width {String} - Width
///  @param $height {String} - Height
///
///  @example
///  @include radio(4, 3); // 4/3 ratio
///  -----------------------------
@mixin ratio($width: 16, $height: 9) {
    display: flex;

    &::before {
        display: block;
        width: 0;
        height: 0;
        padding-bottom: percentage(math.div($height, $width));
        content: '';
    }
}

///   Allow element to fill is parent
///
///  @access public
///  @group placeholders
///
///  @example
///  @extend %fit-parent;
///  -----------------------------
@mixin fit-parent {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

// Mixin to call the tag style on metatada or tag
@mixin tag-style($type, $theme) {
    @include lumx-typography($type, 'custom');

    min-height: var(--lumx-#{$type}-height);
    padding: 0 var(--lumx-#{$type}-state-default-padding-horizontal);
    border-radius: var(--lumx-#{$type}-border-radius);
    border-style: solid;
    border-width: var(--lumx-#{$type}-state-default-border-width);
    display: flex;
    align-items: center;
    margin: $lumx-chip-group-spacing 0;
    margin-right: $lumx-chip-group-spacing;
    background-color: var(--lumx-#{$type}-state-default-#{$theme}-background-color);
    color: var(--lumx-#{$type}-state-default-#{$theme}-color);
    border-color: var(--lumx-#{$type}-state-default-#{$theme}-border-color);
}

@mixin tag-separator-style($type, $theme) {
    @include lumx-typography($type, 'custom');

    content: var(--lumx-#{$type}-list-separator);
    margin-right: $lumx-chip-group-spacing;
    color: var(--lumx-#{$type}-state-default-#{$theme}-color);
}

/**
 * Style to visually hide an element but leave it in the dom and the accessibility tree.
 **/
@mixin visually-hidden() {
    border: 0 !important;
    clip: rect(0 0 0 0) !important;
    clip-path: inset(50%) !important;
    width: 1px !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    white-space: nowrap !important;
}

/**
 * Style to add the required *
 **/
@mixin required() {
    &:before {
        margin-right: $lumx-spacing-unit-tiny;
        content: '*';
        font-weight: 700;
        color: lumx-color-variant('red', 'N');
    }
}

/**
 * Force the display of a block on a specific number of lines
**/
@mixin max-lines($lines) {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $lines;
    overflow: hidden;
    text-overflow: ellipsis;
}

/**
 * Add styles before & after an element while avoiding having a "before" section display if an "after" section was
 displayed.
 */
@mixin between($selector) {
  &:not(:first-child):not(#{$selector} + #{$selector}):not(.visually-hidden + #{$selector}) {
    @content('before')
  }
  &:not(:last-child) {
    @content('after')
  }
}
