@import '@lumapps/lumx/scss/variables.scss';

.select-field-multiple {
    padding: 0 $lumx-spacing-unit-big;

    &__search-field {
        padding: 0 !important;
    }

    .lumx-list-subheader i {
        margin: 0 !important;
    }

    &__list {
        overflow: scroll;
    }
}
