@import '../../../scss/variables.scss';

.text {
    &--truncated {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &__left-icon {
        margin-right: $lumx-spacing-unit-tiny;
    }

    &__right-icon {
        margin-left: $lumx-spacing-unit-tiny;
    }
}
