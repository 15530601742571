@import '../../../scss/variables.scss';

.inline-dialog {
    &__header {
        justify-content: space-between;
        margin-bottom: $lumx-spacing-unit;
    }

    &__footer {
        justify-content: flex-end;
    }

    &__wrapper {
        padding: 0 $lumx-spacing-unit-huge;
    }
}