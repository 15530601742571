@import '../../../scss/variables.scss';

.async-dropdown {
    &__popover-content {
        min-width: $lumx-size-xxl;
    }
    &__no-data {
        padding: $lumx-spacing-unit-regular $lumx-spacing-unit-big;
        text-align: center;
        min-height: $lumx-size-m;
    }
}
