@import '@lumapps/lumx/scss/variables';
@import '@lumapps/wrex-core/scss/mixins.scss';

.wrex-content-td {
    border: solid 1px lumx-color-variant('dark', 'L3');
    padding: $lumx-spacing-unit-huge $lumx-spacing-unit-big;

    .wrex-content-table--theme-dark & {
        border: double 1px lumx-color-variant('light', 'L5');
    }
    .wrex-editor--theme-dark & {
        border: double 1px lumx-color-variant('light', 'L5');
    }

    &--is-in-selection {
        border: double 1px lumx-color-variant('blue', 'N');
        background-color: lumx-color-variant('blue', 'L5');
    }
}

.wrex-content-th {
    font-weight: bold;
}
