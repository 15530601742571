@import '../../../../scss/variables.scss';

$item-wrapper: '.lumx-list-item__wrapper';
$item-link: '.lumx-list-item__link';
$item-content: '.lumx-list-item__content';

.dropdown-skeleton-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: map-get($lumx-list-item-sizes, 'small');

    @each $key, $size in $lumx-sizes {
        &--#{$key} {
            min-width: $size;
        }
    }


    #{$item-wrapper} {
        padding: 0 $lumx-spacing-unit-big;
    }

    #{$item-content} {
        display: flex;
        flex-direction: row;
        align-items: center;
        align-content: baseline;
        justify-content: flex-start;
    }
    .skeleton {
        &__circle {
            width: map-get($lumx-sizes, 'xs');
            height: map-get($lumx-sizes, 'xs');
        }
        &__rectangle {
            margin: 0;
            margin-left: $lumx-spacing-unit-regular;
            height: map-get($lumx-sizes, 'xs');
            flex: 1;
        }
    }
}
