@import '@lumapps/lumx/scss/variables.scss';

.combobox-option {
    position: relative;

    &__trigger {
        @include lumx-list-item-content;
        align-items: center;
        color: $lumx-color-dark-N;
        text-decoration: none;
        display: flex;
        flex: 1;
        overflow: hidden;

        // Make the whole item clickable
        &::before {
            content: '';
            cursor: pointer;
            position: absolute;
            left: 0;
            bottom: 0;
            right: 0;
            top: 0;
            z-index: 2;
        }
    }

    &__after {
        @include lumx-list-item-after('tiny');
        z-index: 3;
    }
}
