@import '@lumapps/lumx/scss/variables';

.notification-stack {
  position: absolute;
  right: 0;
  bottom: 0;
  width: fit-content;

  > * {
    position: fixed;
    right: $lumx-spacing-unit-huge;
    bottom: $lumx-spacing-unit-huge;
    animation: unset;

    @media (prefers-reduced-motion: no-preference) {
      transition: all $lumx-notification-transition-duration;
    }
  }
}
