@import '@lumapps/lumx/scss/variables';

.extended-notification {
  @include lumx-elevation(2);

  background: $lumx-color-light-N;
  width: 320px;
  overflow: hidden;

  &__header {
    display: flex;
    justify-content: flex-end;
    padding: $lumx-spacing-unit-big;
    padding-bottom: 0;
  }

  &__title {
    text-align: center;
  }

  &__content {
    padding: $lumx-spacing-unit-huge;
    padding-top: 0;
  }
}
