@import '../../../scss/variables.scss';

.lumx-header-label {
    display: flex;
    align-items: center;

    &__parent {
        @include lumx-typography('headline');
        display: inline-block;
    }

    &__title {
        &--with-subtitle {
            line-height: 1.2;
        }
    }

    &__parent-icon {
        display: inline-block;
    }
}
