@import '@lumapps/lumx/scss/variables.scss';

.bookmarks {
    @include lumx-list;

    display: flex;
    flex-wrap: wrap;
    padding: 0;

    &.loading {
        height: 100px;

        > div {
            margin: auto;
        }
    }
}
