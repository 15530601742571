@import '@lumapps/lumx/scss/variables.scss';

$header-top-bar-position-no-content-header: 108px;

.header {
    &__skip-link {
        position: absolute;
        top: $lumx-spacing-unit;
        left: $lumx-spacing-unit;
        padding: $lumx-spacing-unit;
        background: $lumx-color-dark-N;
        transition: opacity $fade-speed ease-in-out;
        opacity: 0;
        pointer-events: none;

        &:focus {
            opacity: 1;
            pointer-events: all;
        }
    }
}


/**
* As the "skip link" will focus the element they target, we need to
* add margin equal to the top bar height if targeted by an anchor.
* Especially useful for the skip links.
**/
#maincontent {
    @supports (scroll-margin-top: 0) {
        @include mq($until: desktop) {
            scroll-margin-top: $header-top-bar-height;
        }

        @include mq($from: desktop) {
            scroll-margin-top: $header-height;
        }
    }
}

/**
* Add a fixed scroll margin top to the main nav
* to make sure the skip link works properly
* when focusing a parent navigation
**/
#main-nav {
    scroll-margin-top: $header-top-bar-height;
}

.header--reworked-ui .header-top {
    $self: &;
    background-color: $lumx-color-light-N;

    .theme-material & {
        --lumx-button-emphasis-low-state-default-theme-light-color: var(--lumx-color-grey-N);
    }

    &.sticky {
        position: fixed;
        z-index: $header-z-index;
        top: 0;
        right: 0;
        left: 0;
    }

    @include mq($until: desktop) {
        @include lumx-elevation(4, 'L5');
    }

    &__wrapper {
        @include wrapper;

        display: flex;
        height: $header-top-bar-height;
        align-items: center;
    }

    &__mobile-side-nav {
        margin-right: $lumx-spacing-unit-big;
    }

    &__actions {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: $lumx-spacing-unit-big !important;
        flex: 1;
        justify-content: flex-end;
    }

    &__search {
        flex: 1;
        text-align: right;
    }

    &__search,
    &__contribution {
        @include mq($from: desktop) {
            margin-right: $lumx-spacing-unit-big;
        }

        &-not-connected {
            margin-right: 0;
        }
    }

    &__contribution {
        @include mq($until: desktop) {
            margin: 0 $lumx-spacing-unit;
        }
    }

    &__user-settings {
        margin: 0 $lumx-spacing-unit-big;
    }

    &__logo {
        flex: inherit !important;
        margin-right: 0;
    }
}

/** override specific */
.header--reworked-ui .header-main-nav {
    background-color: $lumx-color-light-N;

    &.sticky {
        position: fixed;
        z-index: $header-z-index;
        top: $header-top-bar-height;
        right: 0;
        left: 0;
    }
}

.header--reworked-ui .header-main-nav--child {
    background-color: $lumx-color-light-N;

    &.header-main-nav--child-no-content-header:not(.sticky) {
        top: $header-top-bar-position-no-content-header;
    }

    &.sticky {
        position: fixed;
        z-index: $header-z-index;
        top: $header-top-bar-height;
        right: 0;
        left: 0;
    }
}

.header__skeleton--triangle {
    background: none !important;
    height: map-get($lumx-sizes, 'xxs');
    width: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 20px solid rgba(0, 0, 0, 0.12);
}

// When the page is on edit mode, we need to move the navigation
// 320 pixels to the right (so left = 320) which is the width of the settings menu
.app-content--is-pushed {
    .header--reworked-ui .header-main-nav--child,
    .header--reworked-ui .header-main-nav {
        &.sticky {
            left: 320px !important;
        }
    }
}

@media print {
    .header-top {
        display: none;
    }
}
